<template>
    <div>
        <v-row class="mx-0 mt-n8 mb-16">
            <v-col cols="12" sm="12" md="12" class="pl-3">
                <div class="underline-gradient">
                    <a class="del-underline" target="_blank"  @click="show = !show">{{$t("common.poolPerformanceAnalyses")}}</a>
                </div>
            </v-col>
            <v-col cols="12" v-if="show && responsive == 'PC'">
                <v-row>
                    <v-col cols="12">{{$t("portfolio.startingDate")}}: {{ obj.date == null ? '--' : CommonJS.formatBritishDate(obj.date) }}</v-col>
                </v-row>
                <div v-if="tab == 'history'">
                    <v-row dense>
                        <v-col>
                            <span class="pointer" @click="tab = 'history'" :class="tab == 'history' ? 'underline-gradient lightred--text' :''">{{$t("portfolio.history")}}</span>
                            <span class="ml-5 pointer" @click="tab = 'recent'" :class="tab == 'recent' ? 'underline-gradient lightred--text' :''">{{$t("portfolio.recent")}}</span>
                        </v-col>
                        <v-col cols="10" class="mb-5">
                            <span v-for="item in selectList" @click="historyCurrent = item.value" class="mr-5 pointer" :class="historyCurrent == item.value ? 'underline-gradient lightred--text' : ''">{{item.text}}</span>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (historyCurrent == null || historyCurrent == '30days' || historyCurrent == 'all')" class="mb-5">
                        <v-col cols="12" class="" v-if="historyCurrent == 'all'"><h5 class="">{{$t("portfolio.1m")}}</h5></v-col>
                        <v-col cols="12" class="px-0">
                            <v-data-table :headers="historyHeader" :items="obj.history.defy1Month" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right">
                                            <td class="text-left">{{ item.currency }}</td>
                                            <td>{{ item.start.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (historyCurrent == null || historyCurrent == '60days' || historyCurrent == 'all')" class="mb-5">
                        <v-col cols="12" class="" v-if="historyCurrent == 'all'"><h5 class="">{{$t("portfolio.2m")}}</h5></v-col>
                        <v-col cols="12"  class="px-0">
                            <v-data-table :headers="historyHeader" :items="obj.history.defy2Month" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right">
                                            <td class="text-left">{{ item.currency }}</td>
                                            <td>{{ item.start.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (historyCurrent == null || historyCurrent == '1quarter' || historyCurrent == 'all')" class="mb-5">
                        <v-col cols="12" class="" v-if="historyCurrent == 'all'"><h5 class="">{{$t("portfolio.3m")}}</h5></v-col>
                        <v-col cols="12"  class="px-0">
                            <v-data-table :headers="historyHeader" :items="obj.history.defy3Month" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right">
                                            <td class="text-left">{{ item.currency }}</td>
                                            <td>{{ item.start.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (historyCurrent == null || historyCurrent == '6months' || historyCurrent == 'all')" class="mb-5">
                        <v-col cols="12" class="" v-if="historyCurrent == 'all'"><h5 class="">{{$t("portfolio.6m")}}</h5></v-col>
                        <v-col cols="12"  class="px-0">
                            <v-data-table :headers="historyHeader" :items="obj.history.defy6Month" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right">
                                            <td class="text-left">{{ item.currency }}</td>
                                            <td>{{ item.start.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (historyCurrent == null || historyCurrent == '1year' || historyCurrent == 'all')" class="mb-5">
                        <v-col cols="12" class="" v-if="historyCurrent == 'all'"><h5 class="">{{$t("portfolio.1y")}}</h5></v-col>
                        <v-col cols="12"  class="px-0">
                            <v-data-table :headers="historyHeader" :items="obj.history.defy1Year" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right">
                                            <td class="text-left">{{ item.currency }}</td>
                                            <td>{{ item.start.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (historyCurrent == null || historyCurrent == 'sinceInception' || historyCurrent == 'all')" class="mb-5">
                        <v-col cols="12" class="" v-if="historyCurrent == 'all'"><h5 class="">{{$t("portfolio.sinceInception")}}</h5></v-col>
                        <v-col cols="12"  class="px-0">
                            <v-data-table :headers="historyHeader" :items="obj.history.sinceInception" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right">
                                            <td class="text-left">{{ item.currency }}</td>
                                            <td>{{ item.start.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="loading">
                        <v-col cols="12">
                            <v-skeleton-loader type="text@2" ></v-skeleton-loader>
                        </v-col>
                    </v-row>
                </div>
                <div v-if="tab == 'recent'">
                    <v-row dense>
                        <v-col>
                            <span class="pointer" @click="tab = 'history'" :class="tab == 'history' ? 'underline-gradient lightred--text' :''">{{$t("portfolio.history")}}</span>
                            <span class="ml-5 pointer" @click="tab = 'recent'" :class="tab == 'recent' ? 'underline-gradient lightred--text' :''">{{$t("portfolio.recent")}}</span>
                        </v-col>
                        <v-col cols="10" class="mb-5">
                            <span v-for="item in selectList" @click="recentCurrent = item.value" class="mr-5 pointer" :class="recentCurrent == item.value ? 'underline-gradient lightred--text' : ''">{{item.text}}</span>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (recentCurrent == null || recentCurrent == '30days' || recentCurrent == 'all')" class="mb-5">
                        <v-col cols="12" class="" v-if="recentCurrent == 'all'"><h5 class="">{{$t("portfolio.1m")}}</h5></v-col>
                        <v-col cols="12" class="px-0">
                            <v-data-table :headers="recentHeader" :items="obj.recent.defy1Month" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right">
                                            <td class="text-left">{{ item.currency }}</td>
                                            <td>{{ item.current.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.start == 0 ? '--' : item.start.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (recentCurrent == null || recentCurrent == '60days' || recentCurrent == 'all')" class="mb-5">
                        <v-col cols="12" class="" v-if="recentCurrent == 'all'"><h5 class="">{{$t("portfolio.2m")}}</h5></v-col>
                        <v-col cols="12" class="px-0">
                            <v-data-table :headers="recentHeader" :items="obj.recent.defy2Month" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right">
                                            <td class="text-left">{{ item.currency }}</td>
                                            <td>{{ item.current.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.start == 0 ? '--' : item.start.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (recentCurrent == null || recentCurrent == '1quarter' || recentCurrent == 'all')" class="mb-5">
                        <v-col cols="12" v-if="recentCurrent == 'all'"><h5 class="">{{$t("portfolio.3m")}}</h5></v-col>
                        <v-col cols="12" class="px-0">
                            <v-data-table :headers="recentHeader" :items="obj.recent.defy3Month" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right">
                                            <td class="text-left">{{ item.currency }}</td>
                                            <td>{{ item.current.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.start == 0 ? '--' : item.start.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (recentCurrent == null || recentCurrent == '6months' || recentCurrent == 'all')" class="mb-5">
                        <v-col cols="12" class="" v-if="recentCurrent == 'all'"><h5 class="">{{$t("portfolio.6m")}}</h5></v-col>
                        <v-col cols="12" class="px-0">
                            <v-data-table :headers="recentHeader" :items="obj.recent.defy6Month" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right">
                                            <td class="text-left">{{ item.currency }}</td>
                                            <td>{{ item.current.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.start == 0 ? '--' : item.start.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (recentCurrent == null || recentCurrent == '1year' || recentCurrent == 'all')" class="mb-5">
                        <v-col cols="12" class="" v-if="recentCurrent == 'all'"><h5 class="">{{$t("portfolio.1y")}}</h5></v-col>
                        <v-col cols="12" class="px-0">
                            <v-data-table :headers="recentHeader" :items="obj.recent.defy1Year" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right">
                                            <td class="text-left">{{ item.currency }}</td>
                                            <td>{{ item.current.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.start == 0 ? '--' : item.start.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (recentCurrent == null || recentCurrent == 'sinceInception' || recentCurrent == 'all')" class="mb-5">
                        <v-col cols="12" class="" v-if="recentCurrent == 'all'"><h5 class="">{{$t("portfolio.sinceInception")}}</h5></v-col>
                        <v-col cols="12" class="px-0">
                            <v-data-table :headers="recentHeader" :items="obj.recent.sinceInception" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right">
                                            <td class="text-left">{{ item.currency }}</td>
                                            <td>{{ item.current.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.start == 0 ? '--' : item.start.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="loading">
                        <v-col cols="12">
                            <v-skeleton-loader type="text@2" ></v-skeleton-loader>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
            <v-col cols="12" v-if="responsive != 'PC'">
                <v-row class="mt-n5">
                    <v-col cols="12" class="dark-gray--title">{{$t("portfolio.startingDate")}}: {{ obj.date == null ? '--' : CommonJS.formatBritishDate(obj.date) }}</v-col>
                    <v-col cols="12" class="dark-gray--title">
                        <span class="pointer" @click="tab = 'history'" :class="tab == 'history' ? 'underline-gradient lightred--text' :''">{{$t("portfolio.history")}}</span>
                        <span class="ml-5 pointer" @click="tab = 'recent'" :class="tab == 'recent' ? 'underline-gradient lightred--text' :''">{{$t("portfolio.recent")}}</span>
                    </v-col>
                    <v-col cols="12">
                        <v-select :items="selectList" class="fs-14 dark-gray--title" item-text="text" item-value="value" v-model="historyCurrent" hide-details dense v-if="tab == 'history'" hide-details dense></v-select>
                        <v-select :items="selectList" class="fs-14" item-text="text" item-value="value" v-model="recentCurrent" hide-details dense v-if="tab == 'recent'"></v-select>
                    </v-col>
                    <v-col cols="12" v-for="item in obj[tab].defy1Month" v-if="!loading && ((tab == 'history' && (historyCurrent == '30days' || historyCurrent == 'all')) || (tab == 'recent' && (recentCurrent == '30days' || recentCurrent == 'all')))">
                        <v-row>
                            <v-col cols="12" class="dark-gray--title" v-if="tab == 'history' && historyCurrent == 'all'"><h5 class="">{{$t("portfolio.1m")}}</h5></v-col>
                            <v-col cols="12" class="dark-gray--title" v-if="tab == 'recent' && recentCurrent == 'all'"><h5 class="">{{$t("portfolio.1m")}}</h5></v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                            <v-col cols="6" class="dark-gray--title">{{$t("portfolio.currency")}}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text">{{item.currency}}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='history'">{{$t("portfolio.startingValue")}}</v-col>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='recent'">{{$t("portfolio.currentValue")}}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='history'">{{ item.start.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='recent'">{{ item.current.toFixed(4) }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title">{{$t("portfolio.roe")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.roe == 0 ? 'dark-gray--text' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title">{{$t("portfolio.pl")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.pl == 0 ? 'dark-gray--text' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='history'">{{$t("portfolio.endingValue")}}</v-col>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='recent'">{{$t("portfolio.startingValue")}}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='history'">{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='recent'">{{ item.start == 0 ? "--" : item.start.toFixed(4) }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" v-for="item in obj[tab].defy2Month" v-if="!loading && ((tab == 'history' && (historyCurrent == '60days' || historyCurrent == 'all')) || (tab == 'recent' && (recentCurrent == '60days' || recentCurrent == 'all')))">
                        <v-row>
                            <v-col cols="12" class="dark-gray--title" v-if="tab == 'history' && historyCurrent == 'all'"><h5 class="">{{$t("portfolio.2m")}}</h5></v-col>
                            <v-col cols="12" class="dark-gray--title" v-if="tab == 'recent' && recentCurrent == 'all'"><h5 class="">{{$t("portfolio.2m")}}</h5></v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                            <v-col cols="6" class="dark-gray--title">{{$t("portfolio.currency")}}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text">{{item.currency}}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='history'">{{$t("portfolio.startingValue")}}</v-col>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='recent'">{{$t("portfolio.currentValue")}}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='history'">{{ item.start.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='recent'">{{ item.current.toFixed(4) }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title">{{$t("portfolio.roe")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.roe == 0 ? 'dark-gray--text' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title">{{$t("portfolio.pl")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.pl == 0 ? 'dark-gray--text' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='history'">{{$t("portfolio.endingValue")}}</v-col>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='recent'">{{$t("portfolio.startingValue")}}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='history'">{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='recent'">{{ item.start == 0 ? "--" : item.start.toFixed(4) }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" v-for="item in obj[tab].defy3Month" v-if="!loading && ((tab == 'history' && (historyCurrent == '1quarter' || historyCurrent == 'all')) || (tab == 'recent' && (recentCurrent == '1quarter' || recentCurrent == 'all')))">
                        <v-row>
                            <v-col cols="12" class="dark-gray--title" v-if="tab == 'history' && historyCurrent == 'all'"><h5 class="">{{$t("portfolio.3m")}}</h5></v-col>
                            <v-col cols="12" class="dark-gray--title" v-if="tab == 'recent' && recentCurrent == 'all'"><h5 class="">{{$t("portfolio.3m")}}</h5></v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                            <v-col class="dark-gray--title" cols="6">{{$t("portfolio.currency")}}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text">{{item.currency}}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='history'">{{$t("portfolio.startingValue")}}</v-col>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='recent'">{{$t("portfolio.currentValue")}}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='history'">{{ item.start.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='recent'">{{ item.current.toFixed(4) }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title">{{$t("portfolio.roe")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.roe == 0 ? 'dark-gray--text' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title">{{$t("portfolio.pl")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.pl == 0 ? 'dark-gray--text' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='history'">{{$t("portfolio.endingValue")}}</v-col>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='recent'">{{$t("portfolio.startingValue")}}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='history'">{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='recent'">{{ item.start == 0 ? "--" : item.start.toFixed(4) }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" v-for="item in obj[tab].defy6Month" v-if="!loading && ((tab == 'history' && (historyCurrent == '6months' || historyCurrent == 'all')) || (tab == 'recent' && (recentCurrent == '6months' || recentCurrent == 'all')))">
                        <v-row>
                            <v-col cols="12" class="dark-gray--title" v-if="tab == 'history' && historyCurrent == 'all'"><h5 class="">{{$t("portfolio.6m")}}</h5></v-col>
                            <v-col cols="12" class="dark-gray--title" v-if="tab == 'recent' && recentCurrent == 'all'"><h5 class="">{{$t("portfolio.6m")}}</h5></v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                            <v-col cols="6" class="dark-gray--title">{{$t("portfolio.currency")}}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text">{{item.currency}}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='history'">{{$t("portfolio.startingValue")}}</v-col>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='recent'">{{$t("portfolio.currentValue")}}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='history'">{{ item.start.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='recent'">{{ item.current.toFixed(4) }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title">{{$t("portfolio.roe")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.roe == 0 ? 'dark-gray--text' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title">{{$t("portfolio.pl")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.pl == 0 ? 'dark-gray--text' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='history'">{{$t("portfolio.endingValue")}}</v-col>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='recent'">{{$t("portfolio.startingValue")}}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='history'">{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='recent'">{{ item.start == 0 ? "--" : item.start.toFixed(4) }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" v-for="item in obj[tab].defy1Year" v-if="!loading && ((tab == 'history' && (historyCurrent == '1year' || historyCurrent == 'all')) || (tab == 'recent' && (recentCurrent == '1year' || recentCurrent == 'all')))">
                        <v-row>
                            <v-col cols="12" class="dark-gray--title" v-if="tab == 'history' && historyCurrent == 'all'"><h5 class="">{{$t("portfolio.1y")}}</h5></v-col>
                            <v-col cols="12" class="dark-gray--title" v-if="tab == 'recent' && recentCurrent == 'all'"><h5 class="">{{$t("portfolio.1y")}}</h5></v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                            <v-col cols="6" class="dark-gray--title">{{$t("portfolio.currency")}}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text">{{item.currency}}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='history'">{{$t("portfolio.startingValue")}}</v-col>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='recent'">{{$t("portfolio.currentValue")}}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='history'">{{ item.start.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='recent'">{{ item.current.toFixed(4) }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title">{{$t("portfolio.roe")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.roe == 0 ? 'dark-gray--text' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title">{{$t("portfolio.pl")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.pl == 0 ? 'dark-gray--text' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='history'">{{$t("portfolio.endingValue")}}</v-col>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='recent'">{{$t("portfolio.startingValue")}}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='history'">{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='recent'">{{ item.start == 0 ? "--" : item.start.toFixed(4) }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" v-for="item in obj[tab].sinceInception" v-if="!loading && ((tab == 'history' && (historyCurrent == 'sinceInception' || historyCurrent == 'all')) || (tab == 'recent' && (recentCurrent == 'sinceInception' || recentCurrent == 'all')))">
                        <v-row>
                            <v-col cols="12" class="dark-gray--title" v-if="tab == 'history' && historyCurrent == 'all'"><h5 class="">{{$t("portfolio.sinceInception")}}</h5></v-col>
                            <v-col cols="12" class="dark-gray--title" v-if="tab == 'recent' && recentCurrent == 'all'"><h5 class="">{{$t("portfolio.sinceInception")}}</h5></v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                            <v-col cols="6" class="dark-gray--title">{{$t("portfolio.currency")}}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text">{{item.currency}}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='history'">{{$t("portfolio.startingValue")}}</v-col>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='recent'">{{$t("portfolio.currentValue")}}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='history'">{{ item.start.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='recent'">{{ item.current.toFixed(4) }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title">{{$t("portfolio.roe")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.roe == 0 ? 'dark-gray--text' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title">{{$t("portfolio.pl")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.pl == 0 ? 'dark-gray--text' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='history'">{{$t("portfolio.endingValue")}}</v-col>
                            <v-col cols="6" class="dark-gray--title" v-if="tab=='recent'">{{$t("portfolio.startingValue")}}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='history'">{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right dark-gray--text" v-if="tab=='recent'">{{ item.start == 0 ? "--" : item.start.toFixed(4) }}</v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" v-if="loading">
                        <v-skeleton-loader type="text@5" ></v-skeleton-loader>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<style scoped>
.theme--dark .v-data-table,.theme--light .v-data-table {
    background-color: transparent !important;
}
</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    export default {
        data(){
            return {
                tab: "history",
                show:false,
                historyCurrent:'sinceInception',
                recentCurrent:'sinceInception',
                loading:false,
                obj:{
                    history:{},
                    recent:{},
                    date:"--",
                },
                historyHeader:[
                    {text:this.$t("portfolio.currency"),width:"20%",class:"pl-1"},
                    {text:this.$t("portfolio.startingValue"),align:"end",width:"20%"},
                    {text:this.$t("portfolio.roe"),align:"end",width:"20%"},
                    {text:this.$t("portfolio.pl"),align:"end",width:"20%"},
                    {text:this.$t("portfolio.endingValue"),align:"end",width:"20%"},
                ],
                recentHeader:[
                    {text:this.$t("portfolio.currency"),width:"20%",class:"pl-1"},
                    {text:this.$t("portfolio.currentValue"),align:"end",width:"20%"},
                    {text:this.$t("portfolio.roe"),align:"end",width:"20%"},
                    {text:this.$t("portfolio.pl"),align:"end",width:"20%"},
                    {text:this.$t("portfolio.startingValue"),align:"end",width:"20%"},
                ],
                selectList: [
                    {text: this.$t("portfolio.1m"),value: "30days"},
                    {text: this.$t("portfolio.2m"),value: "60days"},
                    {text: this.$t("portfolio.3m"),value: "1quarter"},
                    {text: this.$t("portfolio.6m"),value: "6months"},
                    {text: this.$t("portfolio.1y"),value: "1year"},
                    {text: this.$t("portfolio.sinceInception"),value: "sinceInception"},
                    {text: this.$t("portfolio.all"),value: "all"},
                ],
            }
        },
        components: {
        },
        created(){
        },
        mounted(){
            this.getPerformance();
        },
        computed: {
            ...mapGetters(["responsive",'darkMode']),
        },
        watch:{
            // 修改一个当前选中的周期 另一个也修改
            historyCurrent(val){
                if(this.recentCurrent != val){
                    this.recentCurrent = val;
                }
            },
            recentCurrent(val){
                if(this.historyCurrent != val){
                    this.historyCurrent = val;
                }
            }
        },
        methods: {
            getPerformance(){
                this.loading = true;
                let api = config.baseUrl + "/asset/public/iobots/performance/defy";
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.obj = data.result;
                        this.loading = false;
                    }else{
                        this.loading = false;
                    }
                })
            },
        },
    }
</script>