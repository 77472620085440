<template>
<div>
    <div class="mb-3 font-5x" v-if="responsive == 'PC'">
        <v-row>
            <v-col cols="6">
                <div>{{ option.series[0].data.length == 0 ? '0.0000' : '$' + option.series[0].data[option.series[0].data.length - 1].toFixed(4) }}</div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6">
                <span :class="performance == 0 ? '' : (performance > 0 ? 'green--text' : 'red--text')" class="d-flex flex-row-reverse">{{performance == 0 ? 0 : (performance > 0 ? '+' + performance.toFixed(2) : performance.toFixed(2))}}%</span>
            </v-col>
        </v-row>
    </div>
    <div class="mb-3 font-4x" v-if="responsive == 'mobile' || responsive == 'pad'">
        <div v-if="option.series[0].data.length > 0">{{ '$' + option.series[0].data[option.series[0].data.length - 1].toFixed(4) }}</div>
        <div v-if="option.series[0].data.length == 0">0.0000</div>
        <div :class="performance == 0 ? '' : (performance > 0 ? 'green--text' : 'red--text')">{{performance == 0 ? 0 : (performance > 0 ? '+' + performance.toFixed(2) : '-' + performance.toFixed(2))}}%</div>
    </div>
    <div class="mb-3 fs-12" v-if="responsive == 'PC'">
        <span @click="day=7" class="pointer mr-1" :class="day == 7 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1w') }}</span>
        <span @click="day=30" class="pointer ml-1 mr-1" :class="day == 30 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1m') }}</span>
        <span @click="day=90" class="pointer ml-1 mr-1" :class="day == 90 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.3m') }}</span>
        <span @click="day=365" class="pointer ml-1 mr-1" :class="day == 365 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1y') }}</span>
        <span @click="day=0" class="pointer ml-1 mr-1" :class="day == 0 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.all') }}</span><br>
        <span class="grey--text">{{$t('highcharts.assetsTrendLineChart.mouseDragToZoom')}}</span>
    </div>
    <div class="mb-3 fs-12" v-if="responsive == 'mobile'|| responsive == 'pad'">
        <span @click="day=7" class="pointer mr-1" :class="day == 7 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1w') }}</span>
        <span @click="day=30" class="pointer ml-1 mr-1" :class="day == 30 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1m') }}</span>
        <span @click="day=90" class="pointer ml-1 mr-1" :class="day == 90 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.3m') }}</span>
        <span @click="day=365" class="pointer ml-1 mr-1" :class="day == 365 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1y') }}</span>
        <span @click="day=0" class="pointer ml-1 mr-1" :class="day == 0 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.all') }}</span><br>
        <span class="grey--text">{{$t('highcharts.assetsTrendLineChart.mouseDragToZoom')}}</span>
    </div>
    <div :id="id" v-if="responsive == 'PC'"></div>
    <div :id="id" v-if="responsive == 'mobile' || responsive == 'pad'"></div>
</div>
</template>

<style>
svg.highcharts-root {
    font-family: "Lexend Deca", sans-serif !important;
}

.test tspan:active {
    text: expression(target="_blank");
}
</style>

<script>
import config from '@/config.js';
import Highcharts from 'highcharts/highstock';
import {
    mapGetters
} from "vuex";
export default {
    data() {
        return {
            day: 90,
            currency: 'USD',
            id: 'NavLineChartDefi',
            quasarNav: null,
            chart: null,
            loading: false,
            performance: 0,
            option: {
                // 图表类型
                chart: {
                    backgroundColor: this.darkMode ? 'dark' : '', 
                    renderTo: 'NavLineChartDefi',
                    type: 'line',
                    // x方向和y方向都可以用鼠标拖拽放大
                    zoomType: 'xy'
                },
                title: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#9E9E9E'
                    },
                },
                subtitle: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#999999'
                    },
                },
                // x轴
                xAxis: {
                    categories: [],
                    lineWidth: 0,
                    tickInterval: 10,
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%m/%d/%Y %H:%M:%S',
                        second: '%m/%d/%Y %H:%M:%S',
                        minute: '%m/%d/%Y %H:%M',
                        hour: '%m/%d/%Y %H:%M',
                        day: '%m/%d',
                        week: '%m/%d',
                        month: '%m/%Y',
                        year: '%Y'
                    },
                    labels: {
                        enabled: false
                    }
                },
                // y轴
                yAxis: {
                    title: {
                        text: null
                    },
                    visible: true,
                    // 横线宽度设置为0表示不显示横线
                    gridLineWidth: 0,
                    opposite: true,
                    offset: 20,
                    labels: {
                        align: "left",
                        x: -10,
                        formatter: function () {
                            return this.value.toFixed(2);
                        }
                    },
                },
                // 鼠标悬浮提示框
                tooltip: {
                    valueDecimals: 4,
                    valuePrefix: '',
                    valueSuffix: '',
                    followTouchMove: true,
                    dateTimeLabelFormats: {
                        millisecond: '%m/%d/%Y %H:%M:%S UTC',
                        second: '%m/%d/%Y %H:%M:%S UTC',
                        minute: '%m/%d/%Y %H:%M UTC',
                        hour: '%m/%d/%Y %H:%M UTC',
                        day: '%m/%d/%Y %H:%M UTC',
                        week: '%m/%d %H:%M UTC',
                        month: '%m/%Y',
                        year: '%Y'
                    }
                },
                // 数据
                series: [{
                    // type: 'spline',
                    name: "iob Defy NAV",
                    data: [],
                    color: '#ff0000',
                    lineWidth: 3
                }],
                noData: {
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: '#303030'
                    }
                },
                // 图例导航
                legend: {
                    enabled: false,
                    align: 'left',
                    itemStyle: {
                        color: '#999999'
                    },
                    itemHoverStyle: {
                        color: '#999999'
                    },
                    itemHiddenStyle: {
                        color: '#606063'
                    },
                    title: {
                        style: {
                            color: '#C0C0C0',
                            fontFamily: '\'Lexend Deca\', sans-serif'
                        }
                    }
                },
                navigation: {
                    buttonOptions: {
                        symbolStroke: 'black',
                        theme: {
                            fill: '#EEEEEE',
                        },
                    }
                },
                // 图形参数
                plotOptions: {
                    line: {
                        lineWidth: 2,
                        cursor: 'pointer',
                        marker: {
                            enabled: false
                        }
                    }
                },
                // 版权信息
                credits: {
                    enabled: true,
                    href: config.fund,
                    text: '',
                    className: 'a',
                    position: {
                        x: -12,
                        y: -25
                    },
                    style: {
                        fontSize: '12px',
                        color: 'white',
                        target: '_black'
                    }
                },
                exporting: {
                    enabled: true,
                    filename: 'IOB-Defy-NAV',
                    buttons: {
                        contextButton: {
                            align: "left",
                            verticalAlign: "top",
                            x: -10,
                            y: 0
                        }
                    }
                },
                loading: {
                    style: {
                        backgroundColor: 'silver'
                    },
                    labelStyle: {
                        color: 'white',
                        fontSize: '36px'
                    }
                }
            },
        }
    },
    props: {

    },
    watch: {
        day(val) {
            this.getAssetsTrend();
        },
        currency() {
            if (this.quasarNav) {
                if (this.currency == 'BTC') {
                    this.option.series[0].data = this.quasarNav.quasarNavInBTC;
                } else {
                    this.option.series[0].data = this.quasarNav.quasarNavInUSD;
                }
            }
            // 重新渲染图表
            this.renderChart();
        },
        // 监听加载中
        loading(newVal) {
            if (this.chart) {
                if (newVal) {
                    this.chart.showLoading();
                } else {
                    this.chart.hideLoading();
                }
            }
        },
    },
    created() {

    },
    mounted() {
        this.getAssetsTrend();
        Highcharts.setOptions({
            lang: {
                noData: "",
                contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
                printChart: this.$t('highcharts.printChart'),
                resetZoom: this.$t('highcharts.resetZoom'),
                resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
                downloadPNG: this.$t('highcharts.downloadPNG'),
                downloadJPEG: this.$t('highcharts.downloadJPEG'),
                downloadPDF: this.$t('highcharts.downloadPDF'),
                downloadSVG: this.$t('highcharts.downloadSVG')
            }
        });
        this.option.credits.style.fontSize = this.responsive == 'mobile' ? '10px' : '12px';
        this.renderChart();
    },
    computed: {
        ...mapGetters(['responsive','darkMode'])
    },
    components: {

    },
    methods: {
        getAssetsTrend() {
            this.loading = true;
            this.performance = 0;
            this.option.series[0].data = [];
            this.option.xAxis.categories = [];
            let api = config.baseUrl + "/asset/public/getDefyNav?day=" + this.day;
            this.$http.get(api).then(res => {
                let data = res.data;
                if (data.code == 200) {
                    this.getLast(data.result.navInUSD,data.result.date);
                } else {
                    this.loading = false;
                    this.noData();
                }
            })
        },
        getLast(navInUSD,date){
            let api = config.baseUrl + "/asset/public/getDefyNavFromNet";
            this.$http.get(api).then(res => {
                let data = res.data;
                if(data.code == 200){
                    var tempData = [];
                    var tempDate = [];
                    tempData = navInUSD;
                    tempDate = date;
                    let delayTime = new Date(new Date()).toJSON().split('T')[0];
                    tempData.push(data.result)
                    tempDate.push(delayTime)
                    this.option.series[0].data = tempData;
                    this.option.xAxis.categories = tempDate;
                    this.loading = false;
                    this.getPerformance();
                    this.renderChart();
                }
            });
        },
        formatDateFunction(timestamp) {
            if (timestamp == 0) {
                return '--';
            } else {
                if ("localTime" == localStorage.getItem("iobots-time")) {
                    return formatDate(timestamp);
                } else {
                    return formatUTCDate(timestamp)
                }
            }
        },
        getPerformance(){
            var first = this.option.series[0].data[0];
            var last = this.option.series[0].data[this.option.series[0].data.length-1];
            this.performance = (last / first - 1) * 100;
        },
        // 渲染图表
        renderChart() {
            this.chart = Highcharts.chart(this.id, this.option);
        },
        noData() {
            Highcharts.setOptions({
                lang: {
                    noData: this.$t('highcharts.noData'),
                    contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
                    printChart: this.$t('highcharts.printChart'),
                    resetZoom: this.$t('highcharts.resetZoom'),
                    resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
                    downloadPNG: this.$t('highcharts.downloadPNG'),
                    downloadJPEG: this.$t('highcharts.downloadJPEG'),
                    downloadPDF: this.$t('highcharts.downloadPDF'),
                    downloadSVG: this.$t('highcharts.downloadSVG')
                }
            });
            this.renderChart();
        },
    }
}
</script>
