<template>
  <div>
    <div class="ml-2 mb-3 fs-12">
      <span @click="day=7" class="pointer mr-1" :class="day == 7 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1w') }}</span>
      <span @click="day=30" class="pointer ml-1 mr-1" :class="day == 30 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1m') }}</span>
      <span @click="day=90" class="pointer ml-1 mr-1" :class="day == 90 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.3m') }}</span>
      <span @click="day=365" class="pointer ml-1 mr-1" :class="day == 365 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1y') }}</span>
      <span @click="day=0" class="pointer ml-1 mr-1" :class="day == 0 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.all') }}</span>
      <v-row>
        <v-col cols="3" sm="2" md="1">
          <v-menu ref="starMenu" v-model="starMenu" :close-on-content-click="false" :return-value.sync="startDate">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="startDate" label="Start Date" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker show-current="false" v-model="startDate" scrollable locale="zh-cn" :max="endDate" @input="[starMenu = false,$refs.starMenu.save(startDate)]"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3" sm="2" md="1">
          <v-menu ref="endMenu" v-model="endMenu" :close-on-content-click="false" :return-value.sync="endDate">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="endDate" label="End Date" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker show-current="false" v-model="endDate" scrollable locale="zh-cn" :min="startDate" :max="new Date().toISOString().substr(0, 10)" @input="[endMenu = false,$refs.endMenu.save(endDate)]"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <br>
      <span class="grey--text">{{$t('highcharts.assetsTrendLineChart.mouseDragToZoom')}}</span>
      <!-- <a class="pointer ml-1 mr-3 grey--text del-underline float-right" :href="domainName">{{$t('highcharts.dataFromIOB')}}</a> -->
    </div>
    <div :id="id" class="mr-2" style="height: 500px" v-if="responsive == 'PC'"></div>
    <div :id="id" class="mr-2" style="height: 290px;" v-if="responsive == 'mobile'"></div>
  </div>
</template>
<style>
 svg.highcharts-root{
    font-family: "Lexend Deca", sans-serif!important;
  }
</style>
<script>
  import config from '@/config.js';
  import Highcharts from 'highcharts/highstock';
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {
        startDate: new Date(new Date() - 90 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
        starMenu:false,
        endMenu:false,
        day:90,
        id: 'CryptoPriceMarketCapLineChart',
        domainName: config.domainName,
        loading:false,
        // 生成的整个图表对象
        chart: null,
        option:{
          // 图表类型
          chart: {
            backgroundColor: this.darkMode ? 'dark' : '',
            renderTo: 'CryptoPriceMarketCapLineChart',
            type: 'line',
            // x方向和y方向都可以用鼠标拖拽放大
            zoomType: 'xy'
          },
          title: {
            text: '',
            align:'left',
            style: {
              color: '#9E9E9E'
            },
          },
          subtitle: {
            text: '',
            align:'left',
            style: {
              color: '#999999'
            },
          },
          // x轴
          xAxis: {
            categories:[],
            lineWidth: 0,
            tickInterval: 10,
            type: 'datetime',
            dateTimeLabelFormats: {
              millisecond: '%m/%d/%Y %H:%M:%S',
              second: '%m/%d/%Y %H:%M:%S',
              minute: '%m/%d/%Y %H:%M',
              hour: '%m/%d/%Y %H:%M',
              day: '%m/%d',
              week: '%m/%d',
              month: '%m/%Y',
              year: '%Y'
            },
            labels: {
              enabled: false
            }
          },
          // y轴
          yAxis: {
            title: {
              text: null
            },
            visible: true,
            // 横线宽度设置为0表示不显示横线
            gridLineWidth: 0,
            opposite:true,
            offset: 40,
            labels:{
              align: "left",
              x: -10,
              formatter:function(){
                return this.value.toFixed(2);
              }
            },
          },
          // 鼠标悬浮提示框
          tooltip: {
            valueDecimals: 4,
            valuePrefix: '',
            valueSuffix: '',
            followTouchMove: true,
            dateTimeLabelFormats: {
              millisecond: '%m/%d/%Y %H:%M:%S UTC',
              second: '%m/%d/%Y %H:%M:%S UTC',
              minute: '%m/%d/%Y %H:%M UTC',
              hour: '%m/%d/%Y %H:%M UTC',
              day: '%m/%d/%Y %H:%M UTC',
              week: '%m/%d %H:%M UTC',
              month: '%m/%Y',
              year: '%Y'
            }
          },
          // 数据
          series: [
            // {
            // type: 'spline',
            //   name: 'SPX/BTC',
            //   data: [],
            //   color: '#2196f3',
            //   lineWidth: 3
            // }
            // ,
            // {
            //   name: 'China A50/BTC',
            //   data: [],
            //   color: '#FD5B58'
            // }
          ],
          noData: {
            style: {
              fontWeight: 'bold',
              fontSize: '15px',
              color: '#303030'
            }
          },
          // 图例导航
          legend: {
            enabled: true,
            itemStyle: {
                color: '#999999'
            },
            itemHoverStyle: {
                color: '#999999'
            },
            itemHiddenStyle: {
                color: '#606063'
            },
            title: {
                style: {
                    color: '#C0C0C0',
                    fontFamily: '\'Lexend Deca\', sans-serif'
                }
            }
          },
          navigation: {
            buttonOptions: {
              symbolStroke:'black',
              theme: {
                  fill: '#EEEEEE',
              },
            }
          },
          // 图形参数
          plotOptions: {
            line: {
              lineWidth: 2,
              cursor: 'pointer',
              marker: {
               enabled: false
             }
            }
          },
          // 版权信息
          credits: {
            enabled: false,
            href: config.domainName,
            text: null
          },
          exporting: {
            filename: 'CryptoPriceMarketCapLineChart',
            buttons: {
              contextButton: {
                align: "left",
                verticalAlign: "top",
                x: 1,
                y: 0
              }
            }
          },
          loading: {
            style: {
              backgroundColor: 'silver'
            },
            labelStyle: {
              color: 'white',
              fontSize: '36px'
            }
          }
        },
      }
    },
    props:{

    },
    watch: {
      day(val){
        this.getCmc();
      },
      darkMode(val){
        this.option.navigation.buttonOptions.symbolStroke = val ? 'white':'black';
        this.option.navigation.buttonOptions.theme.fill = val ? '#303030':'#EEEEEE';
        this.renderChart();
      },
      startDate(){
        this.getCmcByInterval();
      },
      endDate(){
        this.getCmcByInterval();
      },
      // 监听加载中
      loading(newVal){
        if (this.chart) {
          if (newVal) {
            this.chart.showLoading();
          } else {
            this.chart.hideLoading();
          }
        }
      },
    },
    created(){

    },
    mounted() {
		this.getCmcLineChartObj();
		Highcharts.setOptions({ lang: {
		  // noData: this.$t('highcharts.noData'),
		  noData: "",
		  contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
		  printChart: this.$t('highcharts.printChart'),
		  resetZoom: this.$t('highcharts.resetZoom'),
		  resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
		  downloadPNG: this.$t('highcharts.downloadPNG'),
		  downloadJPEG: this.$t('highcharts.downloadJPEG'),
		  downloadPDF: this.$t('highcharts.downloadPDF'),
		  downloadSVG: this.$t('highcharts.downloadSVG')
    }});
    this.renderChart();
    },
    computed: {
      ...mapGetters(['darkMode', 'responsive'])
    },
    components: {

    },
    methods:{
      getCmcLineChartObj(){
        this.loading = true;
        let api = config.baseUrl + "/cmc/public/getChartObj";
        this.$http.get(api).then(res=>{
          let data = res.data;
          if(data.code == 200){
            this.option.series = data.result;
            this.renderChart();
            this.getCmc();
          }else{
            this.loading = false;
            this.noData();
          }
        })
      },
      getCmc(){
        if(this.day != null){
          this.loading = true;
          this.option.navigation.buttonOptions.symbolStroke = this.darkMode ? 'white':'black';
          this.option.navigation.buttonOptions.theme.fill = this.darkMode ? '#303030':'#EEEEEE';
          for(var i = 0 ; i < this.option.series.length ; i++){
            this.option.series[i].data = [];
          }
          let api = config.baseUrl + "/cmc/public/chart?day=" + this.day;
          this.$http.get(api).then(res => {
            let data = res.data;
            if (data.code == 200){
              for(var i = 0 ; i < this.option.series.length ; i++){
                this.option.series[i].data = data.result[this.option.series[i].name];
              }
              this.option.xAxis.categories = data.result.date;
              // 重新渲染图表
              this.loading = false;
              this.renderChart();
            }else{
              this.loading = false;
              this.noData();
            }
          })
        }
      },
      getCmcByInterval(){
        this.day = null;
        this.loading = true;
        var start = new Date(this.startDate).getTime();
        var end = new Date(this.endDate).getTime();
        let api = config.baseUrl + "/cmc/public/chart?start=" + start + "&end=" + end;
        this.$http.get(api).then(res => {
          let data = res.data;
          if (data.code == 200){
          for(var i = 0 ; i < this.option.series.length ; i++){
            this.option.series[i].data = data.result[this.option.series[i].name];
          }
              this.option.xAxis.categories = data.result.date;
              // 重新渲染图表
              this.renderChart();
              this.loading = false;
          }else{
            this.loading = false;
            this.noData();
          }
        })
      },
      formatDateFunction(timestamp){
        if (timestamp == 0) {
          return '--';
        } else {
          if( "localTime" == localStorage.getItem("iobots-time")){
            return formatDate(timestamp);
          }else{
            return formatUTCDate(timestamp)
          }
        }
      },
      // 渲染图表
      renderChart(){
        this.chart = Highcharts.chart(this.id, this.option);
      },
      noData(){
        Highcharts.setOptions({ lang: {
          noData: this.$t('highcharts.noData'),
          contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
          printChart: this.$t('highcharts.printChart'),
          resetZoom: this.$t('highcharts.resetZoom'),
          resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
          downloadPNG: this.$t('highcharts.downloadPNG'),
          downloadJPEG: this.$t('highcharts.downloadJPEG'),
          downloadPDF: this.$t('highcharts.downloadPDF'),
          downloadSVG: this.$t('highcharts.downloadSVG')
        }});
        this.renderChart();
      },
    }
  }
</script>
