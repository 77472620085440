<template>
  <div ref="screenshot">
    <div class="ml-2 mb-10 mr-5">
      <h4 v-if="option.series[0].data.length > 0">
        <span class="lightred--text">BTC/GOLD</span>
        <span class="float-right">{{ formatNum(option.series[0].data[option.series[0].data.length - 1].toFixed(4)) }}</span>
      </h4>
    </div>
    <div class="ml-2 mb-3 fs-12">
      <span @click="day=7" class="pointer mr-1" :class="day == 7 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1w') }}</span>
      <span @click="day=30" class="pointer ml-1 mr-1" :class="day == 30 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1m') }}</span>
      <span @click="day=90" class="pointer ml-1 mr-1" :class="day == 90 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.3m') }}</span>
      <span @click="day=365" class="pointer ml-1 mr-1" :class="day == 365 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1y') }}</span>
      <span @click="day=0" class="pointer ml-1 mr-1" :class="day == 0 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.all') }}</span>
      <span v-show="!isTakingScreenshot" class="float-right mr-3">
          <!-- <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.reverse')" @click="generateImage">mdi-sync</v-icon> -->
          <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.snapshotAndShare')" @click="generateImage">mdi-camera</v-icon>
          <v-icon size="18" class="lineblue--text mr-1" :title="$t('common.fullScreen')" @click="toggleFullscreen">mdi-fullscreen</v-icon>
      </span>
      <br>
      <span class="grey--text">{{$t('highcharts.assetsTrendLineChart.mouseDragToZoom')}}</span>
      <!-- <a class="pointer ml-1 mr-3 grey--text del-underline float-right" :href="domainName">{{$t('highcharts.dataFromIOB')}}</a> -->
    </div>
    <div :id="id" class="mr-2" style="height: 360px" v-if="responsive == 'PC'"></div>
    <div :id="id" class="mr-2" style="height: 290px;" v-if="responsive == 'mobile'"></div>
    <v-dialog v-model="screenshotDialog" persistent width="450">
      <v-card>
        <v-card-title class="grey lighten-2">
          <span class="black--text">{{$t("common.imageURL")}}</span>
          <v-spacer></v-spacer>
          <v-btn light icon @click="screenshotDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-show="!uploadBase64Loading" class="mt-3">
            <v-text-field id="imageHttpUrl" v-model="imageHttpUrl" single-line outlined disabled hide-details class="mt-3 mb-3"></v-text-field>
            <v-btn text small class="text-none lineblue--text pl-0" @click="saveImage()">{{$t("common.save")}}</v-btn>
            <v-btn text small class="text-none lineblue--text" v-clipboard:copy="imageHttpUrl" v-clipboard:success="copySuccess" v-clipboard:error="copyError">{{$t("common.copy")}}</v-btn>
            <v-btn text small class="text-none lineblue--text" :href="imageHttpUrl" target="_black">{{$t("common.open")}}</v-btn>
          </div>
          <div v-show="uploadBase64Loading" class="mt-3 text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<style>
 svg.highcharts-root{
    font-family: "Lexend Deca", sans-serif!important;
  }
</style>
<script>
  import config from '@/config.js';
  import Highcharts from 'highcharts/highstock';
  import { mapGetters } from "vuex";
  import html2canvas from 'html2canvas';
  export default {
    data() {
      return {
        day:90,
        id: 'syntheticIndicesBtcGoldLineChart',
        domainName: config.domainName,
        // 图片弹窗
        screenshotDialog: false,
        // candleChart图片base64地址
        imageBase64Url: null,
        // candleChart图片http地址
        imageHttpUrl: null,
        // 上传图片加载中
        uploadBase64Loading: false,
        // 是否正在截图
        isTakingScreenshot: false,
        chart:null,
        loading:false,
        option:{
          // 图表类型
          chart: {
            backgroundColor: this.darkMode ? 'dark' : '',
            renderTo: 'syntheticIndicesBtcGoldLineChart',
            type: 'line',
            // x方向和y方向都可以用鼠标拖拽放大
            zoomType: 'xy'
          },
          title: {
            text: '',
            align:'left',
            style: {
              color: '#9E9E9E'
            },
          },
          subtitle: {
            text: '',
            align:'left',
            style: {
              color: '#999999'
            },
          },
          // x轴
          xAxis: {
            categories:[],
            lineWidth: 1,
            tickInterval: 10,
            type: 'datetime',
            dateTimeLabelFormats: {
              millisecond: '%m/%d/%Y %H:%M:%S',
              second: '%m/%d/%Y %H:%M:%S',
              minute: '%m/%d/%Y %H:%M',
              hour: '%m/%d/%Y %H:%M',
              day: '%m/%d',
              week: '%m/%d',
              month: '%m/%Y',
              year: '%Y'
            },
            labels: {
              enabled: false
            }
          },
          // y轴
          yAxis: {
            title: {
              text: null
            },
            visible: true,
            // 横线宽度设置为0表示不显示横线
            gridLineWidth: 0,
            opposite:true,
            offset: 40,
            labels:{
              align: "right",
              x: 0,
              formatter:function(){
                return this.value.toFixed(2);
              }
            },
          },
          // 鼠标悬浮提示框
          tooltip: {
            valueDecimals: 4,
            valuePrefix: '',
            valueSuffix: '',
            followTouchMove: true,
            dateTimeLabelFormats: {
              millisecond: '%m/%d/%Y %H:%M:%S UTC',
              second: '%m/%d/%Y %H:%M:%S UTC',
              minute: '%m/%d/%Y %H:%M UTC',
              hour: '%m/%d/%Y %H:%M UTC',
              day: '%m/%d/%Y %H:%M UTC',
              week: '%m/%d %H:%M UTC',
              month: '%m/%Y',
              year: '%Y'
            }
          },
          // 数据
          series: [
            {
            // type: 'spline',
              name: 'BTC/GOLD',
              data: [],
              color: '#ff6f61',
              lineWidth: 3
            }
          ],
          noData: {
            style: {
              fontWeight: 'bold',
              fontSize: '15px',
              color: '#303030'
            }
          },
          // 图例导航
          legend: {
            enabled: true,
            itemStyle: {
                color: '#999999'
            },
            itemHoverStyle: {
                color: '#999999'
            },
            itemHiddenStyle: {
                color: '#606063'
            },
            title: {
                style: {
                    color: '#C0C0C0',
                    fontFamily: '\'Lexend Deca\', sans-serif'
                }
            }
          },
          navigation: {
            buttonOptions: {
              symbolStroke:'black',
              theme: {
                  fill: '#EEEEEE',
              },
            }
          },
          // 图形参数
          plotOptions: {
            line: {
              lineWidth: 2,
              cursor: 'pointer',
              marker: {
               enabled: false
             }
            }
          },
          // 版权信息
          credits: {
            enabled: false,
            href: config.domainName,
            text: null
          },
          exporting: {
            enabled: false,
            filename: 'Synthetic-Indices-BTC-Gold-Chart',
            buttons: {
              contextButton: {
                align: "left",
                verticalAlign: "top",
                x: 1,
                y: 0
              }
            }
          },
          loading: {
            style: {
              backgroundColor: 'silver'
            },
            labelStyle: {
              color: 'white',
              fontSize: '36px'
            }
          }
        },
      }
    },
    props:{

    },
    watch: {
      day(val){
        this.getSyntheticIndices();
      },
      darkMode(val){
        this.option.navigation.buttonOptions.symbolStroke = val ? 'white':'black';
        this.option.navigation.buttonOptions.theme.fill = val ? '#303030':'#EEEEEE';
        this.renderChart();
      },
      // 监听加载中
      loading(newVal){
        if (this.chart) {
          if (newVal) {
            this.chart.showLoading();
          } else {
            this.chart.hideLoading();
          }
        }
      },
    },
    created(){

    },
    mounted() {
      this.getSyntheticIndices();
      Highcharts.setOptions({ lang: {
        noData: this.$t('highcharts.noData'),
        contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
        printChart: this.$t('highcharts.printChart'),
        resetZoom: this.$t('highcharts.resetZoom'),
        resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
        downloadPNG: this.$t('highcharts.downloadPNG'),
        downloadJPEG: this.$t('highcharts.downloadJPEG'),
        downloadPDF: this.$t('highcharts.downloadPDF'),
        downloadSVG: this.$t('highcharts.downloadSVG')
      }});
      this.renderChart();
    },
    computed: {
      ...mapGetters(['darkMode', 'responsive'])
    },
    components: {

    },
    methods:{
      getSyntheticIndices(){
        this.loading = true;
        this.option.navigation.buttonOptions.symbolStroke = this.darkMode ? 'white':'black';
        this.option.navigation.buttonOptions.theme.fill = this.darkMode ? '#303030':'#EEEEEE';
        this.option.series[0].data = [];
        let api = config.baseUrl + "/synthetic/indices/public/indices?base=btcgold&day=" + this.day;
        this.$http.get(api).then(res => {
          let data = res.data;
          if (data.code == 200){
            var btcGold = [];
            for (let i = 0; i < data.result.btcGold.length; i++) {
              btcGold[i] = data.result.btcGold[i] * 100;
            }
            this.option.series[0].data = btcGold;
            // this.option.xAxis.categories = data.result.date;
			let temp = [];
			for(let i=0; i<data.result.date.length; i++){
				let element = data.result.date[i];
				temp.push(this.CommonJS.formatBritishDate(element));		 
			}
			this.option.xAxis.categories = temp;
            this.loading = false;
            // 重新渲染图表
            this.renderChart();
          }else{
            this.loading = false;
            this.noData();
          }
        })
      },
      formatDateFunction(timestamp){
        if (timestamp == 0) {
          return '--';
        } else {
          if( "localTime" == localStorage.getItem("iobots-time")){
            return formatDate(timestamp);
          }else{
            return formatUTCDate(timestamp)
          }
        }
      },
      // 渲染图表
      renderChart(){
        this.chart = Highcharts.chart(this.id, this.option);
      },
      noData(){
        Highcharts.setOptions({ lang: {
          noData: this.$t('highcharts.noData'),
          contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
          printChart: this.$t('highcharts.printChart'),
          resetZoom: this.$t('highcharts.resetZoom'),
          resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
          downloadPNG: this.$t('highcharts.downloadPNG'),
          downloadJPEG: this.$t('highcharts.downloadJPEG'),
          downloadPDF: this.$t('highcharts.downloadPDF'),
          downloadSVG: this.$t('highcharts.downloadSVG')
        }});
        this.renderChart();
      },
      // 生成图片
          generateImage() {
            let pageYoffset = window.pageYoffset;
            let documentElementScrollTop = document.documentElement.scrollTop;
            let scrollTop = document.body.scrollTop;
            window.pageYoffset = 0;
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
            // 正在截图
            this.isTakingScreenshot = true;
            let _this = this;
            // 延时操作，不要问为什么，不延迟不可以
            setTimeout(function() {
              // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
              html2canvas(_this.$refs.screenshot, {
                backgroundColor: _this.darkMode ? '#303030' : '#EEEEEE',
                useCORS: true
              }).then((canvas) => {
                let url = canvas.toDataURL('image/png');
                _this.imageBase64Url = url;
                // 生成截图完毕
                _this.isTakingScreenshot = false;
                window.pageYoffset = pageYoffset;
                document.documentElement.scrollTop = documentElementScrollTop;
                document.body.scrollTop = scrollTop;
                _this.uploadBase64Loading = true;
                _this.screenshotDialog = true;
                // 把生成的base64位图片上传到服务器,生成在线图片地址
                _this.uploadBase64();
              })
            }, 1);
          },
          // 上传base64
          uploadBase64(){
            this.uploadBase64Loading = true;
            let api = config.baseUrl + '/upload/public/base64';
            let param = { base64: this.imageBase64Url };
            this.$http.post(api, param).then(response => {
              let data = response.data;
              this.uploadBase64Loading = false;
              if (data.code == 200) {
                this.imageHttpUrl = data.result;
              } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);
              }
            }, error => {
              this.uploadBase64Loading = false;
              this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
            });
          },
          // 点击下载图片
          saveImage(){
            let a = document.createElement('a');
            // 设置图片地址
            a.href = this.imageBase64Url;
            // 设定下载名称
            a.download = 'iob Synthetic Indices';
            // 点击触发下载
            a.click();
          },
          // 复制成功回调函数
          copySuccess(){
            this.$store.dispatch('snackbarMessageHandler', this.$t('common.copied'));
          },
          // 复制失败回调函数
          copyError(){
            this.$store.dispatch('snackbarMessageHandler', this.$t('common.copyFailed'));
          },
          //全屏
          toggleFullscreen() {
            if (this.chart.fullscreen) {
              this.chart.fullscreen.toggle();
            }
          },
          // 格式化数字
          formatNum(num) {
            return formatNumber(num);
          },
    }
  }
</script>
