<template>
	<div>
        <v-container fluid class="grid-list-xl pb-0 mt-12">
            <v-row>
                <v-col cols="12" class="text-center lightred--text">
                    <!-- <h2 class="font-2x">{{$t("home.managedDefiFunds")}}</h2> -->
                    <h5 class="font-italic">{{$t("home.tradingWithRulerBasedAlgos")}}</h5>
                </v-col>
            </v-row>
            <!-- Portfolio Results -->
            <v-row class="mx-2" justify="center">
                <v-col cols="12" sm="12" md="12" class="lightred--text pl-1 text-center mb-5 mt-3">
                    <span class="fw-bold font-2x">{{$t("home.iobFiDAOManagedPools")}}</span>
                </v-col>
                <v-col cols="12" sm="10" md="10" class="pl-0">
                    <defy-nav-line-chart></defy-nav-line-chart>
                </v-col>
                <v-col cols="12" sm="10" md="10" class="pl-0 mb-15">
                    <defy-analyses></defy-analyses>
                </v-col>
                <v-col cols="12" sm="10" md="10" class="pl-0 mb-15" v-for="(address, index) in poolAddresses" :key="address">
                    <pool-nav-line-chart :address="address"></pool-nav-line-chart>
                </v-col>
            </v-row>
            <!-- Historical Portfolio Results -->
            <!-- <v-row class="mx-2">
                <v-col cols="12" sm="12" md="12" class="lightred--text pl-1 text-center mb-5 mt-3">
                    <span class="fw-bold font-2x">{{$t("home.historicalPortfolioResults")}}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="pl-0">
                    <portfolio-history-line-chart></portfolio-history-line-chart>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="pl-0">
                    <nav-line-chart-quasar></nav-line-chart-quasar>
                </v-col>
            </v-row> -->
            <!-- iob Synthetic Indices -->
            <!-- <v-row class="mx-2">
                <v-col cols="12" sm="12" md="12" class="lightred--text pl-1 text-center mb-5 mt-3">
                    <span class="fw-bold font-2x">{{$t("home.syntheticIndices")}}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="pl-0">
                    <synthetic-indices-line-chart id="BTCSyntheticIndices" :productArray="['China A50/BTC', 'SPX/BTC']"></synthetic-indices-line-chart>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="pl-0">
                    <synthetic-indices-line-chart id="GOLDSyntheticIndices" :productArray="['China A50/GOLD', 'SPX/GOLD']"></synthetic-indices-line-chart>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="pl-0">
                    <synthetic-indices-line-chart id="BTCGOLDSyntheticIndices" :productArray="['BTC/GOLD']"></synthetic-indices-line-chart>
                </v-col>
            </v-row> -->
            <!-- Old Synthetic Indices -->
            <!-- <v-row class="mx-2">
                <v-col cols="12" sm="12" md="12" class="lightred--text pl-1 text-center mb-5 mt-3">
                    <span class="fw-bold font-2x">{{$t("home.syntheticIndices")}}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="pl-0">
                    <synthetic-indices-btc-line-chart></synthetic-indices-btc-line-chart>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="pl-0">
                    <synthetic-indices-gold-line-chart></synthetic-indices-gold-line-chart>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="pl-0">
                    <synthetic-indices-btc-gold-line-chart></synthetic-indices-btc-gold-line-chart>
                </v-col>
            </v-row> -->
            <!-- Trading Signal Results -->
            <!-- <v-row class="mx-1 mb-5 mt-5">
                <v-col cols="12" sm="12" md="12" class="lightred--text pl-1 text-center mb-5 mt-3">
                    <span class="fw-bold font-2x">{{$t("home.tradingSignalResults")}} ({{$t("home.version")}})</span>
                </v-col>
                <v-col cols="12" md="4" xs="4" class="pa-0" v-for="(symbol, index) in existSignalSymbols" :key="index">
                    <div class="" :id="index + '-' + symbol" >
                        <signal-portfolio-chart class="my-4" :symbol="symbol"></signal-portfolio-chart>
                    </div>
                </v-col>
            </v-row> -->
        </v-container>
        <!-- ************************************************************ mobile *********************************************************** -->
        <v-container class="grid-list-xl pb-0 mt-8 mb-16 px-0" v-if="false">
            <v-row dense>
                <v-col cols="12" class="text-center lightred--text">
                   <span class="font-italic fs-10">{{$t("home.tradingWithRulerBasedAlgos")}}</span>
                </v-col>
            </v-row>
            <!-- 选项 基金/指数 -->
           <v-row class="px-1" id="fundOrIndexSelect">
                <v-overflow-btn :class="[darkMode?'grey darken-3':'grey lighten-2']" block class="my-2 fs-20" hide-details :items="typeItems" target="#dropdown-example" v-model="category"></v-overflow-btn>
           </v-row>
            <!-- 基金 -->
            <v-row class="mt-5 pl-2" v-show="type=='fund'">
                <v-col cols="12" class="fw-bold fs-20 ">
                   <h4 class="lightred--text"> {{$t("highcharts.navLineChart.iobQuasarNav")}}<span class="ml-1">({{$t("highcharts.navLineChart.mergedintoDefyHedgedPool")}})</span></h4>
                </v-col>
                <v-col cols="12">
                    <!-- <nav-line-chart></nav-line-chart> -->
                    <nav-line-chart-quasar></nav-line-chart-quasar>
                </v-col>
            </v-row>
            <!-- 指数 -->
                <v-row class="px-1" id="fundOrIndexSelect" v-show="type=='index'">
                    <v-overflow-btn dense :class="[darkMode?'grey darken-3':'grey lighten-2']" block class="my-2 fs-16" hide-details :items="indexItems" target="#dropdown-example" v-model="indexCategory"></v-overflow-btn>
                </v-row>
                <v-row class="mt-5" v-show="type=='index'">
                    <v-col cols="12" v-show="indexType == 'BTC'">
                        <synthetic-indices-btc-line-chart></synthetic-indices-btc-line-chart>
                    </v-col>
                    <v-col cols="12" v-show="indexType == 'GOLD'">
                        <synthetic-indices-gold-line-chart></synthetic-indices-gold-line-chart>
                    </v-col>
                    <v-col cols="12" v-show="indexType == 'BTCGOLD'">
                        <synthetic-indices-btc-gold-line-chart></synthetic-indices-btc-gold-line-chart>
                    </v-col>
                </v-row>
        </v-container>
         <app-footer></app-footer>
         <mobile-btn></mobile-btn>
	</div>
</template> 
<style>
.theme--dark.v-overflow-btn.theme--dark.v-overflow-btn > .v-input__control > .v-input__slot {
    border-color: #424242!important;
}

#fundOrIndexSelect div {
    color: #ff6f61;
}
</style>
<script>
    import Vue from 'vue'
    import { mapGetters } from "vuex";
    import config from "../../config.js";
    import AppFooter from "@/components/common/Footer.vue";
    import SymbolPrice from "../exchange/WebSocket.vue";
    import NavLineChart from "@/highcharts/NAVLineChart";
    import NavLineChartQuasar from "@/highcharts/NAVLineChartQuasar";
    import SyntheticIndicesBtcLineChart from "@/highcharts/SyntheticIndicesBtcLineChart";
    import SyntheticIndicesGoldLineChart from "@/highcharts/SyntheticIndicesGoldLineChart";
    import SyntheticIndicesBtcGoldLineChart from "@/highcharts/SyntheticIndicesBtcGoldLineChart";
    import CryptoPriceMarketCapLineChart from "@/highcharts/CryptoPriceMarketCapLineChart";
    import SignalPortfolioChart from "@/highcharts/SignalPortfolioChart";
    import MobileBtn from "@/components/common/MobileBtn";
    import PortfolioHistoryLineChart from "@/highcharts/PortfolioHistoryLineChart";
    import DefyNavLineChart from "@/highcharts/DefyNAVLineChart";
    import PoolNavLineChart from "@/highcharts/PoolNAVLineChart";
    import SyntheticIndicesLineChart from "@/highcharts/synthetic/SyntheticIndicesLineChart";
    import DefyAnalyses from "@/components/performance/analyses/DefyAnalyses";

    export default {
        data(){
            return{
                typeItems: [this.$t("home.managedDefiFunds"), this.$t("highcharts.syntheticIndices")],
                indexItems: [this.$t("home.btcBasedSynthetic"), this.$t("home.goldBasedSynthetic")],
                category:this.$t("home.managedDefiFunds"),
                indexCategory:this.$t("home.btcBasedSynthetic"),
                type:"fund",
                title:this.$t("home.managedDefiFunds"),
                indexType:"BTC",
                indexTitle:this.$t("home.btcBasedSynthetic"),
                // 池子地址集合
                poolAddresses: [],
                environment:config.environment,
            }
        },
        created(){
            this.getPoolAddresses();
        },
        mounted(){

        },
        computed: {
            ...mapGetters(["responsive",'darkMode','signalSymbols']),
            // 存在信号的交易对
            existSignalSymbols(){
                let symbols = JSON.parse(JSON.stringify(this.signalSymbols));
                // 删除这几个没有新的的交易对
                symbols.remove('ONTUSD');
                symbols.remove('XLMUSD');
                symbols.remove('IOSTUSD');
                symbols.remove('ALGOUSD');
                return symbols;
            }
        },
        components: {
            NavLineChart, SyntheticIndicesBtcLineChart,SyntheticIndicesGoldLineChart,SyntheticIndicesBtcGoldLineChart,CryptoPriceMarketCapLineChart,MobileBtn,NavLineChartQuasar, SignalPortfolioChart, PortfolioHistoryLineChart, DefyNavLineChart, PoolNavLineChart, SyntheticIndicesLineChart,
            DefyAnalyses,
        },
        watch:{
            category(val){
                if(val == this.$t("home.managedDefiFunds")){
                    this.type = "fund";
                }
                if(val == this.$t("highcharts.syntheticIndices")){
                    this.type = "index";
                }
            },
            indexCategory(val){
                if(val == this.$t("home.btcBasedSynthetic")){
                    this.indexType = "BTC";
                }
                if(val == this.$t("home.goldBasedSynthetic")){
                    this.indexType = "GOLD";
                }
            }
        },
        methods: {
            choseType(type){
                this.type = type;
                if(this.type == 'fund'){
                    this.title = this.$t("home.managedDefiFunds");
                }
                if(this.type == 'index'){
                    this.title = this.$t("highcharts.syntheticIndices");
                };
            },
            changeIndex(type){
                this.indexType = type;
                if(this.indexType == "BTC"){
                    this.indexTitle = this.$t("home.btcBasedSynthetic");
                }
                if(this.indexType == "GOLD"){
                    this.indexTitle = this.$t("home.goldBasedSynthetic");
                }
            },
            // 查询池子地址
            getPoolAddresses(){
                let api = config.baseUrl + "/pool/public/dhedge/addresses";
                this.$http.get(api).then(res => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.poolAddresses = data.result;
                    }
                })
            }
        },
    }
</script>
